import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { CHAIN_SUBGRAPH_URL } from 'state/data/slice'

export const client = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const ApolloClientMap: Map<number, ApolloClient<NormalizedCacheObject>> = new Map<
  number,
  ApolloClient<NormalizedCacheObject>
>()
export function getClientByChainId(chainId: number | undefined) {
  const cId = chainId ?? 1
  let client
  if (ApolloClientMap.has(cId)) {
    client = ApolloClientMap.get(cId)
  } else {
    client = new ApolloClient({
      uri: CHAIN_SUBGRAPH_URL[cId],
      cache: new InMemoryCache(),
      queryDeduplication: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    })
    ApolloClientMap.set(cId, client)
  }
  return client!

  // const graphApiUri = chainId ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[1]
  // return new ApolloClient({
  //   uri: graphApiUri,
  //   cache: new InMemoryCache(),
  //   queryDeduplication: true,
  //   defaultOptions: {
  //     watchQuery: {
  //       fetchPolicy: 'no-cache',
  //     },
  //     query: {
  //       fetchPolicy: 'no-cache',
  //       errorPolicy: 'all',
  //     },
  //   },
  // })
}

export const healthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})
