import { Drawer } from '@material-ui/core'
import { initializeAnalytics } from 'components/AmplitudeAnalytics'
import Loader from 'components/Loader'
import TopLevelModals from 'components/TopLevelModals'
import { AccountOverviewManager, AccountsManager } from 'constants/accounts'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { lazy, Suspense, useState } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import { useAccountOverview, useObservedAccounts, useSelectedAccount } from '../hooks/useAccounts'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Accounts from './Accounts'
import AddAccount from './Accounts/AddAccount'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
// import MigrateV2 from './MigrateV2'
// import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
import Position from './Position'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToSwapOnly } from './Swap/redirects'
const Vote = lazy(() => import('./Vote'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const SideBarWrapper = styled(Drawer)`
  width: 180px;
  height: calc(100vh - 76px);
  background-color: white;
  // background-color: ${({ theme }) => theme.bg1};
`

const SideBarWrapperPc = styled.div`
  width: 180px;
  height: calc(100vh - 76px);
  background-color: white;
  // background-color: ${({ theme }) => theme.bg1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none
  `};
`

export default function App() {
  const [selectedAccount, setSelectedAccount] = useSelectedAccount()
  const [accounts, addAccount, removeAccount, editAccountRemark] = useObservedAccounts()
  const [accountOverview, setAccountOverview] = useAccountOverview()
  const [showSideBar, setShowSideBar] = useState(false)
  const handleSideBarTargle = () => {
    setShowSideBar(!showSideBar)
  }

  const history = useHistory()
  useAnalyticsReporter(useLocation())
  initializeAnalytics()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <AccountsManager.Provider
        value={{ selectedAccount, setSelectedAccount, accounts, addAccount, removeAccount, editAccountRemark }}
      >
        <AccountOverviewManager.Provider value={{ accountOverview, setAccountOverview }}>
          {/* <Web3ReactManager> */}
          <AppWrapper>
            <HeaderWrapper>
              <Header showSidebar={handleSideBarTargle} />
              {/* <AccountOverview /> */}
            </HeaderWrapper>
            {/* <SideBarWrapper open={showSideBar} onClose={handleSideBarTargle}>
              <SideBar showSidebar={handleSideBarTargle} />
            </SideBarWrapper>
            <SideBarWrapperPc>
              <SideBar />
            </SideBarWrapperPc> */}
            <BodyWrapper>
              <Popups />
              <Polling />
              <TopLevelModals />
              <Suspense fallback={<Loader />}>
                <Switch>
                  {/* <Route strict path="/vote" component={Vote} /> */}
                  {/* <Route exact strict path="/create-proposal">
                <Redirect to="/vote/create-proposal" />
              </Route> */}
                  {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
                  {/* <Route exact strict path="/uni" component={Earn} />
              <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}

                  <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                  {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                  <Route exact strict path="/swap" component={Swap} /> */}
                  <Route exact strict path="/swap/:contractAddress/:outputCurrency?" component={Swap} />

                  {/* <Route exact strict path="/pool/v2/find" component={PoolFinder} /> */}
                  {/* <Route exact strict path="/pool/v2" component={PoolV2} /> */}
                  {/* <Route exact strict path="/pool" component={Pool} />
                  <Route exact strict path="/pool/:tokenId" component={PositionPage} /> */}

                  {/* <Route exact strict path="/add/v2/:currencyIdA?/:currencyIdB?" component={RedirectDuplicateTokenIdsV2} /> */}
                  {/* <Route
                    exact
                    strict
                    path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                    component={RedirectDuplicateTokenIds}
                  /> */}
                  <Route
                    exact
                    strict
                    path="/add/:contractAddress/:currencyIdA?/:currencyIdB?/:feeAmount?"
                    component={RedirectDuplicateTokenIds}
                  />

                  {/* <Route
                    exact
                    strict
                    path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                    component={AddLiquidity}
                  /> */}
                  <Route
                    exact
                    strict
                    path="/increase/:contractAddress/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                    component={AddLiquidity}
                  />

                  {/* <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
                  {/* <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} /> */}
                  <Route exact strict path="/remove/:contractAddress/:tokenId" component={RemoveLiquidityV3} />

                  {/* <Route exact strict path="/migrate/v2" component={MigrateV2} />
              <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} /> */}

                  {/* 观察地址列表 */}
                  <Route exact strict path="/accounts" component={Accounts} />
                  {/* 添加观察地址 */}
                  <Route exact strict path="/accounts/add" component={AddAccount} />
                  {/* 仓位列表 */}
                  <Route exact strict path="/positions/:address" component={Position} />

                  <Route component={RedirectPathToSwapOnly} />
                </Switch>
              </Suspense>
              <Marginer />
            </BodyWrapper>
          </AppWrapper>
          {/* </Web3ReactManager> */}
        </AccountOverviewManager.Provider>
      </AccountsManager.Provider>
    </ErrorBoundary>
  )
}
