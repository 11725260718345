import { Trans } from '@lingui/macro'
import Grid from '@material-ui/core/Grid'
import Stack from '@material-ui/core/Stack'
import ShowApr from 'components/ShowApr'
import React, { useMemo } from 'react'
import { getAPRByStr, getPoolsByStr, usePoolDataState } from 'state/pools/hooks'
import styled from 'styled-components/macro'
import { PositionDetails } from 'types/position'

const OverviewBox = styled.div`
  width: 40%;
`
const OverviewTotal = styled.div`
  width: 100%;
`
const TotalLiquidity = styled.div`
  display: inline-block;
  .liquidityTitle {
    font-size: 14px;
    line-height: 14px;
    color: #1c1f26;
    font-weight: 400;
    display: inline-block;
  }
  .liquidityData {
    font-size: 24px;
    font-weight: 600;
    color: #1c1f26;
    line-height: 24px;
    margin-top: 6px;
    display: inline-block;
  }
`
const TotalFee = styled.div`
  padding: 5px 4px;
  background: rgba(32, 58, 196, 0.06);
  border-radius: 4px;
  line-height: 12px;
  margin-top: 8px;
  display: inline-block;
  .feeDetail {
    font-size: 12px;
    font-weight: 400;
    color: #203ac4;
    line-height: 12px;
    &.bold {
      font-weight: 500;
    }
  }
`
export const LiquidityItem = styled.div`
  .num {
    font-size: 14px;
    font-weight: 600;
    color: #1c1f26;
    line-height: 14px;
  }
  .name {
    font-size: 12px;
    font-weight: 400;
    color: #bcc2cb;
    line-height: 12px;
    margin-top: 4px;
  }
`
interface PositionLists {
  openPositions: PositionDetails[]
  closedPositions: PositionDetails[]
}

export default function PoolOverview({ openPositions, closedPositions }: PositionLists) {
  const allPositions = [...openPositions, ...closedPositions]
  // const { positionsData } = usePoolOverviewManager()

  const removedNum = closedPositions.length

  // const { pools } = usePoolState()
  const { pools, topVolumeUSDPools, apr } = usePoolDataState()

  const { totalLiquidity, totalFee, inRangeNum, outOfRangeNum } = useMemo(() => {
    let totalLiquidity = 0
    let totalFee = 0
    let inRangeNum = 0
    let outOfRangeNum = 0
    const poolsMap = getPoolsByStr(pools)
    openPositions.forEach((item) => {
      const {
        token0: token0Address,
        token1: token1Address,
        fee: feeAmount,
        liquidity,
        tickLower,
        tickUpper,
        tokenId,
      } = item
      if (poolsMap.has(tokenId.toString())) {
        const data = poolsMap.get(tokenId.toString())
        if (data?.isInRange) {
          inRangeNum += 1
        }
        totalLiquidity += data!.fiatValueOfLiquidity
        totalFee += data!.fiatValueOfFees
      }
    })
    outOfRangeNum = openPositions.length - inRangeNum >= 0 ? openPositions.length - inRangeNum : 0
    return { totalLiquidity, totalFee, inRangeNum, outOfRangeNum }
  }, [pools, openPositions])

  const totalAPR = useMemo(() => {
    const aprMap = getAPRByStr(apr)
    let totalLiquidity = 0
    let totalFee = 0
    for (const item of openPositions) {
      const { tokenId } = item
      const data = aprMap.get(tokenId.toString())
      // 如果没有，说明数据没有取完，返回undefined总比数字一直跳跃变化的好
      if (!data) {
        return undefined
      }

      if (!isNaN(data.apr) && !isNaN(data.fiatValueOfLiquidity)) {
        totalLiquidity += data.fiatValueOfLiquidity
        totalFee += (data.fiatValueOfLiquidity * data.apr) / 365
      }
    }
    return (totalFee * 365) / totalLiquidity
  }, [apr, openPositions])

  return (
    <OverviewBox>
      <OverviewTotal>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item xs={6}>
            <TotalLiquidity>
              <span className="liquidityTitle">
                <Trans>Uniswap V3 Liquidity</Trans>
              </span>
              <br />
              <span className="liquidityData">
                {totalLiquidity.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  useGrouping: true,
                  maximumFractionDigits: 2,
                })}
              </span>
            </TotalLiquidity>
          </Grid>
          <Grid item xs={6}>
            <ShowApr apr={totalAPR} />
          </Grid>
          <Grid item xs={6}>
            <TotalFee>
              <span className="feeDetail">
                <Trans>Unclaimed fees:</Trans>
              </span>
              <span className="feeDetail bold">
                {totalFee.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  useGrouping: true,
                  maximumFractionDigits: 2,
                })}
              </span>
            </TotalFee>
          </Grid>
        </Grid>
        {/*<Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <TotalLiquidity>
            <span className="liquidityTitle">Uniswap V3 流动性</span>
            <br />
            <span className="liquidityData">
              {totalLiquidity.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                useGrouping: true,
                maximumFractionDigits: 2,
              })}
            </span>
          </TotalLiquidity>
          <TotalFee>
            <span className="feeDetail">未收割手续费：</span>
            <span className="feeDetail bold">
              {totalFee.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                useGrouping: true,
                maximumFractionDigits: 2,
              })}
            </span>
          </TotalFee>
          <ShowApr apr={totalAPR} />
        </Stack>*/}
      </OverviewTotal>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={4} sx={{ marginTop: '20px' }}>
        <LiquidityItem>
          <span className="num">{allPositions.length}</span>
          <br />
          <span className="name">
            <Trans>Total</Trans>
          </span>
        </LiquidityItem>
        <LiquidityItem>
          <span className="num">{inRangeNum}</span>
          <br />
          <span className="name">
            <Trans>In range</Trans>
          </span>
        </LiquidityItem>
        <LiquidityItem>
          <span className="num">{outOfRangeNum}</span>
          <br />
          <span className="name">
            <Trans>Out of range</Trans>
          </span>
        </LiquidityItem>
        <LiquidityItem>
          <span className="num">{removedNum}</span>
          <br />
          <span className="name">
            <Trans>Closed</Trans>
          </span>
        </LiquidityItem>
      </Stack>
    </OverviewBox>
  )
}
