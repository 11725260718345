import { createReducer } from '@reduxjs/toolkit'

import { updateContractAddress, updateIsAdmin, updateIsOfficialContract, updateIsTrader } from './actions'

export interface KYContractState {
  readonly isAdmin: boolean | undefined
  readonly isTrader: boolean | undefined
  readonly isOperator: boolean | undefined
  readonly contractAddress: string | undefined
  readonly isOfficialContract: boolean | undefined
}

const initialState: KYContractState = {
  isAdmin: undefined,
  isTrader: undefined,
  isOperator: undefined,
  contractAddress: undefined,
  isOfficialContract: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateIsAdmin, (state, action) => {
      const { isAdmin } = action.payload
      const { isTrader } = state
      const isOperator = isAdmin || isTrader
      return { ...state, isAdmin, isOperator }
    })
    .addCase(updateIsTrader, (state, action) => {
      const { isTrader } = action.payload
      const { isAdmin } = state
      const isOperator = isAdmin || isTrader
      return { ...state, isTrader, isOperator }
    })
    .addCase(updateContractAddress, (state, action) => {
      const { contractAddress } = action.payload
      return { ...state, contractAddress }
    })
    .addCase(updateIsOfficialContract, (state, action) => {
      const { isOfficialContract } = action.payload
      return { ...state, isOfficialContract }
    })
)
