import { createAction } from '@reduxjs/toolkit'

export const updateIsAdmin = createAction<{ isAdmin: boolean | undefined }>('kycontract/updateIsAdmin')
export const updateIsTrader = createAction<{ isTrader: boolean | undefined }>('kycontract/updateIsTrader')
export const updateContractAddress = createAction<{ contractAddress: string | undefined }>(
  'kycontract/updateContractAddress'
)
export const updateIsOfficialContract = createAction<{ isOfficialContract: boolean | undefined }>(
  'kycontract/updateIsOfficialContract'
)
