import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'

export interface Account {
  account: string
  remark: string
}

export function useObservedAccounts() {
  const localObservedAccount: string = localStorage.getItem('UniOceanAccounts') || '[]'
  const [accounts, setAccounts] = useState(JSON.parse(localObservedAccount))
  function handleAdd(account: string, remark?: string) {
    const accountData = { account, remark }
    if (accounts.filter((item: Account) => item.account.toLowerCase() === account.toLowerCase()).length === 0) {
      localStorage.setItem('UniOceanAccounts', JSON.stringify([...accounts, ...[accountData]]))
      setAccounts([...accounts, ...[accountData]])
    }
  }
  function handleDelete(account: string) {
    const accountsFilter = accounts.filter((item: Account) => item.account !== account)
    localStorage.setItem('UniOceanAccounts', JSON.stringify(accountsFilter))
    setAccounts(accountsFilter)
  }

  function editAccountRemark(account: string, remark: string) {
    const newAccounts = accounts.map((item: Account): Account => {
      if (item.account === account) {
        return { account, remark }
      }
      return item
    })
    localStorage.setItem('UniOceanAccounts', JSON.stringify(newAccounts))
    setAccounts(newAccounts)
  }
  return [
    accounts,
    (account: string, remark?: string) => handleAdd(account, remark),
    (data: string) => handleDelete(data),
    (account: string, remark: string) => editAccountRemark(account, remark),
  ]
}

export function useSelectedAccount() {
  const web3React = useWeb3React()
  const web3ReactAccount = web3React.account
  const localSelected: string = localStorage.getItem('UniOceanSelectedAccount') || web3ReactAccount || ''
  const [selectedAccount, setSelectedAccount] = useState<string>(localSelected)

  function handleSelect(account: string) {
    setSelectedAccount(account)
    localStorage.setItem('UniOceanSelectedAccount', account)
  }
  return [selectedAccount, (data: string) => handleSelect(data)] as const
}

export function useAccountOverview() {
  const [overview, setOverview] = useState<any[]>([])
  function handleOverview(data: any[]) {
    setOverview(data)
  }
  return [overview, (data: any[]) => handleOverview(data)] as const
}
