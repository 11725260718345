import { useWeb3React } from '@web3-react/core'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import AddLiquidity from './index'

/**
 * 1. 去重，如果A和B是一样的（ETH、WETH是一样的），则会变成只有一个币
 * @param props
 * @returns
 */
export function RedirectDuplicateTokenIds(
  props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string; feeAmount?: string; contractAddress: string }>
) {
  const {
    match: {
      params: { currencyIdA, currencyIdB, contractAddress },
    },
  } = props

  const { chainId } = useWeb3React()

  // prevent weth + eth
  const isETHOrWETHA =
    currencyIdA === 'ETH' || (chainId !== undefined && currencyIdA === WRAPPED_NATIVE_CURRENCY[chainId]?.address)
  const isETHOrWETHB =
    currencyIdB === 'ETH' || (chainId !== undefined && currencyIdB === WRAPPED_NATIVE_CURRENCY[chainId]?.address)

  if (
    currencyIdA &&
    currencyIdB &&
    (currencyIdA.toLowerCase() === currencyIdB.toLowerCase() || (isETHOrWETHA && isETHOrWETHB))
  ) {
    return <Redirect to={`/add/${contractAddress}/${currencyIdA}`} />
  }
  return <AddLiquidity {...props} />
}
