import { getDefaultProvider } from '@ethersproject/providers'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import ConfirmDialog from '../../components/ConfirmDialog'
import { useAccountManager } from '../../constants/accounts'
import { Account } from '../../hooks/useAccounts'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { isAddress, shortenAddress } from '../../utils'

const PageTitle = styled.h2`
  text-align: left;
  vertical-align: bottom;
  width: 100%;
`
const AccountPageBox = styled.div`
  margin: 0 auto;
  width: 550px;
`
const AccountTypeTitle = styled.p`
  margin-top: 32px;
  margin-bottom: 6px;
  color: #bcc2cb;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  padding-bottom: 4px;
`

const AddAccountLink = styled.a`
  float: right;
  font-size: 14px;
  color: #3f51b5;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export default function Accounts() {
  // const [accounts, , removeAccount, editAccountRemark] = useObservedAccounts()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [handleAccount, setHandleAccount] = useState<string>('')
  const [isCopied, setCopied] = useCopyClipboard()
  const [handleRemark, setHandleRemark] = useState<string>('')
  // const [remarkErr, setRemarkErr] = useState<boolean>(false)
  const { accounts, removeAccount, editAccountRemark } = useAccountManager()
  function deleteAccount(account: string) {
    removeAccount(account)
  }
  function openDeleteDialog() {
    setDeleteDialogOpen(true)
    hideMoreMenu()
  }
  function openEditDialog() {
    const initHandleRemark = accounts.filter((item: Account) => item.account === handleAccount)[0].remark
    setHandleRemark(initHandleRemark)
    setEditDialogOpen(true)
    hideMoreMenu()
  }
  function closeDeleteDialog(account?: string) {
    setDeleteDialogOpen(false)
    if (account) deleteAccount(account)
  }
  function closeEditDialog(data?: any) {
    setEditDialogOpen(false)
    if (data) editAccountRemark(data.account, data.remark)
  }

  function handleCopy(account: string) {
    setHandleAccount(account)
    setCopied(account)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  function showMoreMenu(event: React.MouseEvent<HTMLElement>, account: string) {
    setAnchorEl(event.currentTarget)
    setHandleAccount(account)
  }
  function hideMoreMenu() {
    setAnchorEl(null)
  }
  // const { account } = useWeb3React()
  // const toggleWalletModal = useToggleWalletModal()

  //add account
  const { addAccount, selectedAccount, setSelectedAccount } = useAccountManager()
  const [newAccount, setNewAccount] = useState<string>('')
  const [accountErr, setAccountErr] = useState<boolean>(false)
  const [showAddAccountDialog, setShowAddAccountDialog] = useState<boolean>(false)
  const [remark, setRemark] = useState<string>('')
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewAccount(event.target.value)
  }
  function checkAccount(account: string) {
    if (isAddress(account)) {
      setAccountErr(false)
      return true
    } else {
      setAccountErr(true)
      return false
    }
  }
  function AddAccount(account?: string) {
    if (account) {
      const check: boolean = checkAccount(account)
      if (check && newAccount.trim() !== '') {
        setAccountErr(false)
        addAccount(newAccount.trim(), remark)
        setShowAddAccountDialog(false)
        if (!selectedAccount) {
          setSelectedAccount(newAccount.trim())
        }
      } else {
        setAccountErr(true)
        setShowAddAccountDialog(true)
      }
      setNewAccount('')
      setRemark('')
    } else {
      setAccountErr(false)
      setShowAddAccountDialog(false)
    }
  }
  function showAddDialog() {
    setShowAddAccountDialog(true)
  }
  function remarkChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRemark(event.target.value)
  }
  function editRemarkChange(event: React.ChangeEvent<HTMLInputElement>) {
    setHandleRemark(event.target.value)
  }

  return (
    <AccountPageBox>
      <PageTitle>
        <Trans>Manage Wallets</Trans>
      </PageTitle>
      {/* <AccountTypeTitle>
        <Trans>MY WALLETS</Trans>
      </AccountTypeTitle>
      {!account ? (
        <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
          <Trans>Connect a wallet</Trans>
        </ButtonPrimary>
      ) : (
        <List
          key={account}
          dense={true}
          sx={{
            width: '550px',
            border: '1px solid #BCC2CB',
            padding: '12px 14px',
            marginBottom: '12px',
            borderRadius: '8px',
            cursor: 'pointer',
            ':hover': {
              borderColor: '#1C1F26',
            },
          }}
        > 
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ marginRight: '8px' }}>{subStr(account.toUpperCase())}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={shortenAddress(account)} />
            <ListItemSecondaryAction>
              <Tooltip
                placement="top"
                open={isCopied && account === handleAccount}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t`Copied`}
                arrow
              >
                <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(account)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
      */}
      <AccountTypeTitle>
        合约地址
        <AddAccountLink onClick={showAddDialog}>添加合约地址</AddAccountLink>
      </AccountTypeTitle>

      {accounts.map((item: Account) => {
        return (
          <List
            key={item.account}
            dense={true}
            sx={{
              width: '550px',
              border: '1px solid #BCC2CB',
              padding: '12px 14px',
              marginBottom: '12px',
              borderRadius: '8px',
              cursor: 'pointer',
              ':hover': {
                borderColor: '#1C1F26',
              },
            }}
          >
            <ListItem key={`item_${item.account}`}>
              <ListItemAvatar>
                <AccountImg account={item.account} />
              </ListItemAvatar>
              <Link
                style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }}
                to={'/positions/' + item.account}
                // target="_blank"
              >
                <ListItemText primary={shortenAddress(item.account)} secondary={item.remark} />
              </Link>
              <ListItemSecondaryAction>
                <Tooltip
                  placement="top"
                  open={isCopied && item.account === handleAccount}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={t`Copied`}
                  arrow
                >
                  <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(item.account)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  aria-label="more"
                  id="more-button"
                  aria-controls="more"
                  aria-expanded={openMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={(event) => showMoreMenu(event, item.account)}
                  sx={{ marginLeft: '8px' }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )
      })}

      <ConfirmDialog onClose={closeDeleteDialog} value={handleAccount} open={deleteDialogOpen} title="Remove">
        <span>
          <Trans>Remove address {handleAccount} ?</Trans>
        </span>
      </ConfirmDialog>
      <ConfirmDialog
        onClose={closeEditDialog}
        value={{ account: handleAccount, remark: handleRemark }}
        open={editDialogOpen}
        title={t`Rename`}
      >
        <TextField
          sx={{ width: '100%' }}
          label={t`Rename`}
          value={handleRemark}
          inputProps={{ 'aria-label': t`Rename` }}
          onChange={editRemarkChange}
        />
        <span style={{ marginTop: '12px' }}>
          <Trans>Address：</Trans>
          {handleAccount}
        </span>
      </ConfirmDialog>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={hideMoreMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openEditDialog} disableRipple>
          <EditOutlinedIcon sx={{ marginRight: '8px' }} />
          <Trans>Rename</Trans>
        </MenuItem>
        <MenuItem onClick={openDeleteDialog} disableRipple>
          <DeleteOutlinedIcon sx={{ marginRight: '8px' }} />
          <Trans>Remove</Trans>
        </MenuItem>
      </Menu>
      <ConfirmDialog onClose={AddAccount} value={newAccount} open={showAddAccountDialog} title={t`Add Address`}>
        <TextField
          sx={{ width: '100%' }}
          label={t`EVM-Compatible Addresses`}
          value={newAccount}
          inputProps={{ 'aria-label': t`Add wallet` }}
          onChange={handleChange}
          error={accountErr}
          helperText={accountErr ? t`Not checksum address` : ' '}
        />
        <TextField
          sx={{ width: '75%' }}
          label={t`Remark`}
          value={remark}
          inputProps={{ 'aria-label': t`Remark` }}
          onChange={remarkChange}
        />
      </ConfirmDialog>
    </AccountPageBox>
  )
}

function AccountImg({ account }: { account: string }) {
  const provider = getDefaultProvider()
  const [isContract, setIsContract] = useState(false)
  provider.getCode(account).then((res) => {
    setIsContract(res !== '0x')
  })
  return (
    <>
      {isContract ? (
        <Avatar>
          <InsertDriveFileOutlinedIcon />
        </Avatar>
      ) : (
        <Avatar sx={{ marginRight: '8px' }}>{subStr(account.toUpperCase())}</Avatar>
      )}
    </>
  )
}

function subStr(str: string) {
  return str.substr(2, 2)
}
