import { Trans } from '@lingui/macro'
import { green, red } from '@material-ui/core/colors'
import Stack from '@material-ui/core/Stack'
import Tooltip from '@material-ui/core/Tooltip'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import WarningIcon from '@material-ui/icons/Warning'
import { BigNumber } from 'bignumber.js'
import { useKYStrategyContract } from 'hooks/useKYContract'
import { useAccountOverview } from 'hooks/useRequestAPIs'
import React from 'react'
import { useKYContractState } from 'state/kycontract/hooks'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'

const PoolAccountBox = styled.div`
  width: 55%;
`
const TotalAssets = styled.div`
  span {
    display: inline-block;
    width: 100%;
    &.title {
      font-size: 14px;
      font-weight: 400;
      color: #1c1f26;
      line-height: 14px;
    }
    &.content {
      font-size: 24px;
      font-weight: 600;
      color: #1c1f26;
      line-height: 24px;
      margin-top: 6px;
    }
  }
`
const MainAssetsBox = styled.div`
  margin-top: 20px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #1c1f26;
    line-height: 14px;
    margin-bottom: 10px;
  }
`
const MainAssetsItem = styled.div`
  width: 16.6%;
  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.symbol {
      font-size: 12px;
      font-weight: 400;
      color: #bcc2cb;
      line-height: 12px;
    }
    &.balance {
      font-size: 14px;
      font-weight: 500;
      color: #1c1f26;
      line-height: 14px;
      margin-top: 10px;
    }
    &.value {
      font-size: 12px;
      font-weight: 400;
      color: #bcc2cb;
      line-height: 12px;
      margin-top: 2px;
    }
  }
`
const TokenImage = styled.img`
  width: 12px;
  margin-right: 4px;
`

export function PoolAccountInfo() {
  const { contractAddress, isOfficialContract } = useKYContractState()
  const accountOverview = useAccountOverview(contractAddress)
  const pandaStrategyContract = useKYStrategyContract(contractAddress)

  let totalAssets = new BigNumber(0)
  accountOverview.forEach((item) => {
    totalAssets = totalAssets.plus(new BigNumber(item.value))
  })
  return (
    <PoolAccountBox>
      <TotalAssets>
        <span className="title">
          地址({contractAddress ? shortenAddress(contractAddress, 4) : null})
          {isOfficialContract ? (
            <Tooltip title="官方标准策略合约">
              <VerifiedUserIcon style={{ color: green[500], verticalAlign: 'middle' }} fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title="非标准策略合约，无法进行兑换和仓位相关操作">
              <WarningIcon style={{ color: red[500], verticalAlign: 'middle' }} fontSize="small" />
            </Tooltip>
          )}
        </span>
        <span className="content">
          {totalAssets.toNumber().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 2,
          })}
        </span>
      </TotalAssets>
      <MainAssetsBox>
        <p className="title">
          <Trans>Main Assets</Trans>
        </p>
      </MainAssetsBox>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
        {accountOverview.map((token, index) => {
          return index < 6 ? (
            <MainAssetsItem key={index}>
              <p className="symbol" title={token.symbol}>
                {token.image ? (
                  <TokenImage src={token.image} alt="" />
                ) : (
                  <NotInterestedIcon sx={{ fontSize: '12px', marginRight: '4px' }} />
                )}
                {token.symbol}
              </p>
              <p className="balance" title={token.balance.toString()}>
                {token.balance}
              </p>
              <p className="value" title={token.value.toString()}>
                ${' '}
                {Number(token.value).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </p>
            </MainAssetsItem>
          ) : null
        })}
      </Stack>
    </PoolAccountBox>
  )
}
