import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { parseUnits } from 'ethers/lib/utils'

export function getFormattedAmounts(
  currencyAmountA: CurrencyAmount<Currency>,
  currencyAmountB: CurrencyAmount<Currency>,
  chainId: number
) {
  // 这个是以前的代码，暂时不用了
  // const WETH = WETH9[chainId].address
  // //   const WETH = WETH9_EXTENDED[chainId]
  // const addressA = currencyAmountA.currency.isToken ? currencyAmountA.currency.wrapped.address : WETH
  // const addressB = currencyAmountB.currency.isToken ? currencyAmountB.currency.wrapped.address : WETH
  // const decA = addressA.toLowerCase() === WETH.toLowerCase() ? 18 : currencyAmountA.currency.wrapped.decimals
  // const decB = addressB.toLowerCase() === WETH.toLowerCase() ? 18 : currencyAmountB.currency.wrapped.decimals

  // const amountA = parseUnits(currencyAmountA.toExact(), decA)
  // const amountB = parseUnits(currencyAmountB.toExact(), decB)
  // return { amountA, amountB, addressA, addressB, decA, decB }

  return {
    amountA: parseUnits(currencyAmountA.toExact(), currencyAmountA.currency.decimals),
    amountB: parseUnits(currencyAmountB.toExact(), currencyAmountB.currency.decimals),
    addressA: currencyAmountA.currency.wrapped.address,
    addressB: currencyAmountB.currency.wrapped.address,
    decA: currencyAmountA.currency.decimals,
    decB: currencyAmountB.currency.decimals,
  }
}

/**
 * 根据currencyAmount中的token的address大小进行排序，并返回排序后的结果
 * @param currencyAmountA
 * @param currencyAmountB
 * @param chainId
 * @returns tokenA代表地址小的， tokenB代表地址大的
 */
export function getFormattedAmountsByAddressOrder(
  currencyAmountA: CurrencyAmount<Currency>,
  currencyAmountB: CurrencyAmount<Currency>,
  chainId: number
) {
  // WRAPPED_NATIVE_CURRENCY[chainId]
  // const WETH = WETH9[chainId].address
  const tokenA = currencyAmountA.currency.wrapped
  const tokenB = currencyAmountB.currency.wrapped
  const isSorted = tokenA.sortsBefore(tokenB)
  const [token0, token1] = isSorted ? [tokenA, tokenB] : [tokenB, tokenA]
  const [currencyAmount0, currencyAmount1] = isSorted
    ? [currencyAmountA, currencyAmountB]
    : [currencyAmountB, currencyAmountA]

  const addressA = token0.address
  const addressB = token1.address
  // const decA = addressA.toLowerCase() === WETH.toLowerCase() ? 18 : currencyAmount0.currency.wrapped.decimals
  // const decB = addressB.toLowerCase() === WETH.toLowerCase() ? 18 : currencyAmount1.currency.wrapped.decimals
  const decA = currencyAmount0.currency.wrapped.decimals
  const decB = currencyAmount1.currency.wrapped.decimals

  const amountA = parseUnits(currencyAmount0.toExact(), decA)
  const amountB = parseUnits(currencyAmount1.toExact(), decB)
  return {
    amountA,
    amountB,
    addressA: token0.address,
    addressB: token1.address,
    decA,
    decB,
  }
}
