import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { MouseoverTooltip } from 'components/Tooltip'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <ThemedText.Black fontWeight={500} fontSize={16} style={{ marginRight: '8px' }}>
            <Trans>Swap</Trans>
            <MouseoverTooltip
              text={'实际Swap出来的数量可能低于显示值，这取决于合约中配置的路径。但是不会低于设置的滑点值。'}
            >
              <HelpCircle size="20" color={'gray'} style={{ marginLeft: '8px' }} />
            </MouseoverTooltip>
          </ThemedText.Black>
        </RowFixed>
        <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  )
}
