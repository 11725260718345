import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { useKYContractState } from 'state/kycontract/hooks'

export function CheckIsOfficialContract() {
  const { isOfficialContract } = useKYContractState()

  return (
    <>
      {typeof isOfficialContract === 'undefined' ? null : isOfficialContract ? null : (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="warning">非标准策略合约，无法完成相关操作</Alert>
        </Stack>
      )}
    </>
  )
}
