import { Trans } from '@lingui/macro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useWeb3React } from '@web3-react/core'
import PositionListItem from 'components/PositionListItem'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { PositionDetails } from 'types/position'

const DesktopHeader = styled.div`
  display: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      text-align: right;
      margin-right: 12px;
    }
  }
`

const MobileHeader = styled.div`
  font-weight: medium;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: none;
  }

  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

const ToggleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ToggleLabel = styled.div`
  opacity: 0.6;
  margin-right: 10px;
`

const MobileTogglePosition = styled.div`
  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    position: absolute;
    right: 20px;
  }
`

const TableCellStyle = styled(TableCell)`
  padding: 6px;
`

type PositionListProps = React.PropsWithChildren<{
  positions: PositionDetails[]
  setUserHideClosedPositions: any
  userHideClosedPositions: boolean
  selected: string[]
  setSelected: (data: string[]) => void
}>

export default function PositionList({
  positions,
  setUserHideClosedPositions,
  userHideClosedPositions,
  selected,
  setSelected,
}: PositionListProps) {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = positions.map((n) => n.tokenId.toString())
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // const positionList = useMemo(() => {
  //   const positionList: PositionDetails[] = JSON.parse(JSON.stringify(positions))
  //   // const newPositions: PositionDetails[] = []
  //   // Object.assign(newPositions, positions)
  //   positionList.sort((a, b) => {
  //     return Number(b.tokenId) - Number(a.tokenId)
  //   })
  //   console.log('positionList:', positionList)
  //   return positionList
  // }, [positions])

  // 提高效率，把PositionListItem里的弄出来了
  const theme = useTheme()
  // const { chainId, account, library } = useActiveWeb3React()
  const { chainId, account, provider } = useWeb3React()
  const positionManager = useV3NFTPositionManagerContract()
  const customPositionDetails = { theme, chainId, account, library: provider, positionManager }

  return (
    <>
      <Table sx={{ minWidth: 1000 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCellStyle>
              <Trans>Pair</Trans>
            </TableCellStyle>
            <TableCellStyle align="right">
              <Trans>ID</Trans>
            </TableCellStyle>
            <TableCellStyle align="right">
              <Trans>APR</Trans>
            </TableCellStyle>
            <TableCellStyle align="right">
              <Trans>Price range</Trans>
            </TableCellStyle>
            <TableCellStyle align="right">
              <Trans>Liquidity</Trans>
            </TableCellStyle>
            <TableCellStyle align="right">
              <Trans>Fees</Trans>
            </TableCellStyle>
            <TableCellStyle align="center">
              <Trans>Status</Trans>
            </TableCellStyle>
            <TableCellStyle align="center">
              <Trans>Operation</Trans>
            </TableCellStyle>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions.map((p) => {
            return (
              <PositionListItem
                key={p.tokenId.toString()}
                positionDetails={p}
                selected={selected}
                setSelected={setSelected}
                customPositionDetails={customPositionDetails}
              />
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

// export default function PositionList({
//   positions,
//   setUserHideClosedPositions,
//   userHideClosedPositions,
// }: PositionListProps) {
//   return (
//     <>
//       <DesktopHeader>
//         <div>
//           <Trans>Your positions</Trans>
//           {positions && ' (' + positions.length + ')'}
//         </div>
//         <ToggleWrap>
//           <ToggleLabel>
//             <Trans>Show closed positions</Trans>
//           </ToggleLabel>
//           <Toggle
//             id="desktop-hide-closed-positions"
//             isActive={!userHideClosedPositions}
//             toggle={() => {
//               setUserHideClosedPositions(!userHideClosedPositions)
//             }}
//           />
//         </ToggleWrap>
//       </DesktopHeader>
//       <MobileHeader>
//         <Trans>Your positions</Trans>
//         <ToggleWrap>
//           <ToggleLabel>
//             <Trans>Show closed positions</Trans>
//           </ToggleLabel>
//           <MobileTogglePosition>
//             <Toggle
//               id="mobile-hide-closed-positions"
//               isActive={!userHideClosedPositions}
//               toggle={() => {
//                 setUserHideClosedPositions(!userHideClosedPositions)
//               }}
//             />
//           </MobileTogglePosition>
//         </ToggleWrap>
//       </MobileHeader>
//       {positions.map((p) => {
//         return <PositionListItem key={p.tokenId.toString()} positionDetails={p} />
//       })}
//     </>
//   )
// }
