// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'
import { SwapCallbackState, useSwapCallback as useLibSwapCallBack } from 'lib/hooks/swap/useSwapCallback'
import { ReactNode, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { getFormattedAmounts } from 'utils/getFormattedAmounts'
import { swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import { useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { currencyId } from '../utils/currencyId'
import useENS from './useENS'
import { SignatureData } from './useERC20Permit'
import { useKYStrategyContract } from './useKYContract'
import { AnyTrade } from './useSwapCallArguments'
import useTransactionDeadline from './useTransactionDeadline'

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: AnyTrade | undefined, // trade to execute, required
  allowedSlippage: Percent, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  signatureData: SignatureData | undefined | null
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: ReactNode | null } {
  const { account } = useWeb3React()

  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  const {
    state,
    callback: libCallback,
    error,
  } = useLibSwapCallBack({ trade, allowedSlippage, recipientAddressOrName: recipient, signatureData, deadline })

  const callback = useMemo(() => {
    if (!libCallback || !trade) {
      return null
    }
    return () =>
      libCallback().then((response) => {
        addTransaction(
          response,
          trade.tradeType === TradeType.EXACT_INPUT
            ? {
                type: TransactionType.SWAP,
                tradeType: TradeType.EXACT_INPUT,
                inputCurrencyId: currencyId(trade.inputAmount.currency),
                inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
                expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                outputCurrencyId: currencyId(trade.outputAmount.currency),
                minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
              }
            : {
                type: TransactionType.SWAP,
                tradeType: TradeType.EXACT_OUTPUT,
                inputCurrencyId: currencyId(trade.inputAmount.currency),
                maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
                outputCurrencyId: currencyId(trade.outputAmount.currency),
                outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
              }
        )
        return response.hash
      })
  }, [addTransaction, allowedSlippage, libCallback, trade])

  return {
    state,
    callback,
    error,
  }
}

export function useKYSwapCallback(
  trade: AnyTrade | undefined, // trade to execute, required
  allowedSlippage: Percent, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  signatureData: SignatureData | undefined | null,
  contractAddress: string | undefined
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: ReactNode | null } {
  const { account, chainId, provider } = useWeb3React()

  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()

  // const { address: recipientAddress } = useENS(recipientAddressOrName)
  // const recipient = recipientAddressOrName === null ? account : recipientAddress
  const pandaStrategyContract = useKYStrategyContract(contractAddress)

  return useMemo(() => {
    if (!trade || !provider || !account || !chainId || !pandaStrategyContract) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    const {
      amountA: inAmount,
      amountB: outAmount,
      addressA: inTokenAddress,
      addressB: outTokenAddress,
      decA: inDec,
      decB: outDec,
    } = getFormattedAmounts(trade.inputAmount, trade.outputAmount, chainId)

    // TODO 要先确认下是否支持这个路径，不过这里有BUG：如果有这个路径，则很快可以查询出来，如果没有这个路径，则会一直在显示查询的状态。需要找璞确认下
    // const swapRoute = await pandaStrategyContract.swapRoute(inTokenAddress, outTokenAddress)
    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const minimumAmountOut = ethers.utils.parseUnits(trade.minimumAmountOut(allowedSlippage).toExact(), outDec)
        const maximumAmountIn = ethers.utils.parseUnits(trade.maximumAmountIn(allowedSlippage).toExact(), inDec)

        const estimatedGas = await (() => {
          let estimated: Promise<BigNumber>
          if (trade.tradeType === TradeType.EXACT_INPUT) {
            estimated = pandaStrategyContract.estimateGas
              .exactInput(inTokenAddress, outTokenAddress, inAmount, minimumAmountOut)
              .catch((error) => {
                return pandaStrategyContract.estimateGas.exactInput(
                  inTokenAddress,
                  outTokenAddress,
                  inAmount,
                  minimumAmountOut
                )
              })
          } else {
            estimated = pandaStrategyContract.estimateGas.exactOutput(
              inTokenAddress,
              outTokenAddress,
              outAmount,
              maximumAmountIn
            )
          }
          return estimated
        })()

        if (trade.tradeType === TradeType.EXACT_INPUT) {
          return pandaStrategyContract
            .exactInput(inTokenAddress, outTokenAddress, inAmount, minimumAmountOut, {
              gasLimit: calculateGasMargin(estimatedGas),
            })
            .then((response) => {
              return response.hash
            })
            .catch((error) => {
              if (error?.code === 4001) {
                throw new Error('Transaction rejected.')
              } else {
                // otherwise, the error was unexpected and we need to convey that
                console.error(`Swap failed`, error)
                throw new Error(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
              }
            })
        }
        return pandaStrategyContract
          .exactOutput(inTokenAddress, outTokenAddress, outAmount, maximumAmountIn, {
            gasLimit: calculateGasMargin(estimatedGas),
          })
          .then((response) => {
            return response.hash
          })
          .catch((error) => {
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error)
              throw new Error(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
            }
          })
      },
      error: null,
    }
  }, [trade, provider, account, chainId, pandaStrategyContract, allowedSlippage])

  // const {
  //   state,
  //   callback: libCallback,
  //   error,
  // } = useLibSwapCallBack({ trade, allowedSlippage, recipientAddressOrName: recipient, signatureData, deadline })

  // const callback = useMemo(() => {
  //   if (!libCallback || !trade) {
  //     return null
  //   }
  //   return () =>
  //     libCallback().then((response) => {
  //       addTransaction(
  //         response,
  //         trade.tradeType === TradeType.EXACT_INPUT
  //           ? {
  //               type: TransactionType.SWAP,
  //               tradeType: TradeType.EXACT_INPUT,
  //               inputCurrencyId: currencyId(trade.inputAmount.currency),
  //               inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
  //               expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
  //               outputCurrencyId: currencyId(trade.outputAmount.currency),
  //               minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
  //             }
  //           : {
  //               type: TransactionType.SWAP,
  //               tradeType: TradeType.EXACT_OUTPUT,
  //               inputCurrencyId: currencyId(trade.inputAmount.currency),
  //               maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
  //               outputCurrencyId: currencyId(trade.outputAmount.currency),
  //               outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
  //               expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
  //             }
  //       )
  //       return response.hash
  //     })
  // }, [addTransaction, allowedSlippage, libCallback, trade])

  // return {
  //   state,
  //   callback,
  //   error,
  // }
}

// export function useUOSwapCallback(
//   trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
//   allowedSlippage: Percent, // in bips
//   recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
//   signatureData: SignatureData | undefined | null,
//   contractAddress: string | undefined
// ): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
//   // BigNumber
//   const { account, chainId, library } = useActiveWeb3React()

//   // const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName, signatureData)

//   const addTransaction = useTransactionAdder()

//   // const { address: recipientAddress } = useENS(recipientAddressOrName)
//   // const recipient = recipientAddressOrName === null ? account : recipientAddress
//   const pandaStrategyContract = useKYStrategyContract(contractAddress)

//   return useMemo(() => {
//     if (!trade || !library || !account || !chainId || !pandaStrategyContract) {
//       return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
//     }
//     // if (!recipient) {
//     //   if (recipientAddressOrName !== null) {
//     //     return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
//     //   } else {
//     //     return { state: SwapCallbackState.LOADING, callback: null, error: null }
//     //   }
//     // }
//     const {
//       amountA: inAmount,
//       amountB: outAmount,
//       addressA: inTokenAddress,
//       addressB: outTokenAddress,
//       decA: inDec,
//       decB: outDec,
//     } = getFormattedAmounts(trade.inputAmount, trade.outputAmount, chainId)

//     // TODO 要先确认下是否支持这个路径，不过这里有BUG：如果有这个路径，则很快可以查询出来，如果没有这个路径，则会一直在显示查询的状态。需要找璞确认下
//     // const swapRoute = await pandaStrategyContract.swapRoute(inTokenAddress, outTokenAddress)
//     return {
//       state: SwapCallbackState.VALID,
//       callback: async function onSwap(): Promise<string> {
//         const minimumAmountOut = ethers.utils.parseUnits(trade.minimumAmountOut(allowedSlippage).toExact(), outDec)
//         const maximumAmountIn = ethers.utils.parseUnits(trade.maximumAmountIn(allowedSlippage).toExact(), inDec)

//         const estimatedGas = await (() => {
//           let estimated: Promise<BigNumber>
//           if (trade.tradeType === TradeType.EXACT_INPUT) {
//             estimated = pandaStrategyContract.estimateGas
//               .exactInput(inTokenAddress, outTokenAddress, inAmount, minimumAmountOut)
//               .catch((error) => {
//                 return pandaStrategyContract.estimateGas.exactInput(
//                   inTokenAddress,
//                   outTokenAddress,
//                   inAmount,
//                   minimumAmountOut
//                 )
//               })
//           } else {
//             estimated = pandaStrategyContract.estimateGas.exactOutput(
//               inTokenAddress,
//               outTokenAddress,
//               outAmount,
//               maximumAmountIn
//             )
//           }
//           return estimated
//         })()

//         if (trade.tradeType === TradeType.EXACT_INPUT) {
//           return pandaStrategyContract
//             .exactInput(inTokenAddress, outTokenAddress, inAmount, minimumAmountOut, {
//               gasLimit: calculateGasMargin(chainId, estimatedGas),
//             })
//             .then((response) => {
//               return response.hash
//             })
//             .catch((error) => {
//               if (error?.code === 4001) {
//                 throw new Error('Transaction rejected.')
//               } else {
//                 // otherwise, the error was unexpected and we need to convey that
//                 console.error(`Swap failed`, error)
//                 throw new Error(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
//               }
//             })
//         }
//         return pandaStrategyContract
//           .exactOutput(inTokenAddress, outTokenAddress, outAmount, maximumAmountIn, {
//             gasLimit: calculateGasMargin(chainId, estimatedGas),
//           })
//           .then((response) => {
//             return response.hash
//           })
//           .catch((error) => {
//             if (error?.code === 4001) {
//               throw new Error('Transaction rejected.')
//             } else {
//               // otherwise, the error was unexpected and we need to convey that
//               console.error(`Swap failed`, error)
//               throw new Error(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
//             }
//           })
//       },
//       error: null,
//     }
//   }, [trade, library, account, chainId, recipientAddressOrName, addTransaction, pandaStrategyContract])
// }
