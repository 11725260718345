import { BigNumber } from '@ethersproject/bignumber'
import KY_STRATEGY_ABI from 'abis/ky_strategy.json'
import { KyStrategy } from 'abis/types'
// import { useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useKYContractState } from 'state/kycontract/hooks'

import { useContract } from './useContract'

// 自定义
export function useKYStrategyContract(contractAddress?: string, withSignerIfPossible?: boolean): KyStrategy | null {
  return useContract<KyStrategy>(contractAddress, KY_STRATEGY_ABI, withSignerIfPossible)
}

// 是否是策略的管理员
export function useIsStrategyAdmin(strategy: KyStrategy | null, account: string | undefined): boolean | undefined {
  const res = useSingleCallResult(strategy, 'isAdmin', [account])
  const result = res?.result?.[0]?.toString()
  if (typeof result === 'undefined') return undefined
  return typeof result === 'string' && result === 'true'
}

// 是否是策略的交易员
export function useIsStrategyTrader(strategy: KyStrategy | null, account: string | undefined): boolean | undefined {
  const res = useSingleCallResult(strategy, 'isStrategist', [account])
  const result = res?.result?.[0]?.toString()
  if (typeof result === 'undefined') return undefined
  return typeof result === 'string' && result === 'true'
}

// 策略支持的的底层资产
export function useStrategyUnderlyings(strategy: KyStrategy | null) {
  const res = useSingleCallResult(strategy, 'getUnderlyings')
  const underlyings = res?.result?.[0]?.toString()
  if (typeof underlyings === 'string') {
    return underlyings.split(',')
  }
  return undefined
}

// 是否策略对应仓位的Owner
export function useStrategyPositionOwner(strategy: KyStrategy | null, tokenId: number) {
  const res = useSingleCallResult(strategy, 'positionOwners', [tokenId])
  return res?.result?.[0]?.toString()
}

// 获取策略已经配置的交易路径
// export function useStrategySwapRoute() {}

// 通过钱包地址查询合约中支持的多个币种分别对应的交易限额
export function useStrategyTokensLimit(strategy: KyStrategy | null, account: string | undefined) {
  const underlyings = useStrategyUnderlyings(strategy)
  const params =
    underlyings && account
      ? underlyings.reduce<[string, string][]>((t, v) => {
          return t.concat([[account, v]])
        }, [])
      : [[undefined, undefined]]
  const res = useSingleContractMultipleData(strategy, 'tokenLimit', params)

  if (res.length > 0) {
    return res.reduce<Map<string, BigNumber>>((t, v, index) => {
      if (v?.result?.[0] && v?.result?.[0] instanceof BigNumber) {
        t.set(underlyings![index], v?.result?.[0])
      }
      return t
    }, new Map([]))
  }
  return undefined
}

// 通过钱包地址查询对应单个币种的限额
export function useStrategyTokenLimit(
  strategy: KyStrategy | null,
  account: string | undefined,
  tokenAddress: string | undefined
) {
  const res = useSingleCallResult(strategy, 'tokenLimit', [account, tokenAddress])
  return res?.result?.[0]?.toString()
}

// 获取基金合约
export function useGetVault(strategy: KyStrategy | null) {
  const res = useSingleCallResult(strategy, 'vault')
  return res?.result?.[0]?.toString()
}

// 这个大概率不会用在别的地方，主要是用来判断是否是官方合约用的
function useGetIOToken(strategy: KyStrategy | null): string | undefined {
  const res = useSingleCallResult(strategy, 'ioToken')
  return res?.result?.[0]?.toString()
}

// 判断是否为官方合约
export function useIsOfficialContract(strategy: KyStrategy | null) {
  // 用ioToken来判断是否是官方的合约
  const res = useSingleCallResult(strategy, 'ioToken')
  if (res.error === true) return false
  const value = res?.result?.[0]?.toString()
  return typeof value === 'string' && value.length === 42
}

export function useOperationButtonClick(): { handleOperationButtonClick: (url: string) => void } {
  const routeHistory = useHistory()
  const { isOperator } = useKYContractState()
  return {
    handleOperationButtonClick: useCallback(
      (url: string) => {
        if (!isOperator) return
        routeHistory.push(url)
      },
      [isOperator]
    ),
  }
}
