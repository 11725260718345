import axios from 'axios'
import { AddressMap } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'

export const ETHPLORER_URL: AddressMap = {
  ...constructSameAddressMap('https://api.ethplorer.io', [SupportedChainId.MAINNET]),
  [SupportedChainId.KOVAN]: 'https://kovan-api.ethplorer.io',
}

export function getAddressInfo(address: string, chainId?: number) {
  const baseUrl = chainId ? ETHPLORER_URL[chainId] : ETHPLORER_URL[SupportedChainId.MAINNET]
  return axios.get(`${baseUrl}/getAddressInfo/${address}?apiKey=${process.env.REACT_APP_ETHPLORER_APIKEY}`)
}
