import { useCallback } from 'react'
import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { updateContractAddress, updateIsAdmin, updateIsOfficialContract, updateIsTrader } from './actions'

export function useKYContractState(): AppState['kycontract'] {
  return useAppSelector((state) => state.kycontract)
}

export function useKYContractActionHandlers(): {
  onUpdateIsAdmin: (isAdming: boolean | undefined) => void
  onUpdateIsTrader: (isTrader: boolean | undefined) => void
  onUpdateContractAddress: (contractAddress: string | undefined) => void
  onUpdateIsOfficialContract: (isOfficialContract: boolean | undefined) => void
} {
  const dispatch = useAppDispatch()

  const onUpdateIsAdmin = useCallback(
    (isAdmin: boolean | undefined) => {
      dispatch(updateIsAdmin({ isAdmin }))
    },
    [dispatch]
  )

  const onUpdateIsTrader = useCallback(
    (isTrader: boolean | undefined) => {
      dispatch(updateIsTrader({ isTrader }))
    },
    [dispatch]
  )

  const onUpdateContractAddress = useCallback(
    (contractAddress: string | undefined) => {
      dispatch(updateContractAddress({ contractAddress }))
    },
    [dispatch]
  )

  const onUpdateIsOfficialContract = useCallback(
    (isOfficialContract: boolean | undefined) => {
      dispatch(updateIsOfficialContract({ isOfficialContract }))
    },
    [dispatch]
  )

  return {
    onUpdateIsAdmin,
    onUpdateIsTrader,
    onUpdateContractAddress,
    onUpdateIsOfficialContract,
  }
}
