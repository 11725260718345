import { getAddressInfo } from 'apis/ethplorer'
import { BigNumber } from 'bignumber.js'
import { useMemo, useState } from 'react'

export function useAccountOverview(address: string | undefined, chainId?: number) {
  const [tokens, setTokens] = useState<
    {
      symbol: string
      name: string
      balance: number
      value: number
      rate: number
      image: string
      price: any
    }[]
  >([])

  useMemo(() => {
    if (!address) return
    getAddressInfo(address, chainId).then((res) => {
      if (res.status === 200) {
        const data_ = res.data
        let ethPrice = new BigNumber(0)
        if (data_.ETH.price) {
          ethPrice = new BigNumber(data_.ETH.rawBalance).times(data_.ETH.price.rate).dividedBy(10 ** 18)
        }
        const newTokens = [
          {
            symbol: 'ETH',
            name: 'Ethereum',
            balance: new BigNumber(data_.ETH.balance.toFixed(6)).toNumber(),
            value: new BigNumber(ethPrice.toFixed(2)).toNumber(),
            rate: new BigNumber(new BigNumber(data_.ETH.price.rate).toFixed(2)).toNumber(),
            image: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            price: data_.ETH.price,
          },
        ]
        if (data_.tokens) {
          res.data.tokens.forEach(async (token: any) => {
            try {
              let tokenBalance, tokenValue
              if (token.tokenInfo.decimals) {
                tokenBalance = new BigNumber(token.rawBalance).dividedBy(new BigNumber(10 ** token.tokenInfo.decimals))
              } else {
                tokenBalance = new BigNumber(token.balance)
              }
              if (token.tokenInfo.price) {
                tokenValue = tokenBalance.times(token.tokenInfo.price.rate)
              } else {
                tokenValue = new BigNumber(0)
              }
              const tokenImage = token.tokenInfo.image ? `https://ethplorer.io${token.tokenInfo.image}` : undefined
              newTokens.push({
                ...token.tokenInfo,
                balance: new BigNumber(tokenBalance.toFixed(6)).toNumber(),
                value: new BigNumber(tokenValue.toFixed(2)).toNumber(),
                rate: token.tokenInfo.price
                  ? new BigNumber(token.tokenInfo.price.rate.toFixed(2)).toNumber()
                  : undefined,
                image: tokenImage,
              })
            } catch (e) {
              console.log(e)
            }
          })
        }
        setTokens(newTokens)
      }
    })
  }, [address])
  return tokens
}
