import { Trans } from '@lingui/macro'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Stack from '@material-ui/core/Stack'
// import { useActiveWeb3React } from 'hooks/web3'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { CheckIsOfficialContract } from 'components/Header/CheckIsOfficialContract'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { PoolAccountInfo } from 'components/PoolAccountInfo'
import PositionList from 'components/PositionList'
import { RowBetween, RowFixed } from 'components/Row'
import Toggle from 'components/Toggle'
// import { WETH9_EXTENDED } from 'constants/tokens'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useToken } from 'hooks/Tokens'
import { useOperationButtonClick } from 'hooks/useKYContract'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useV3Positions } from 'hooks/useV3Positions'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Inbox } from 'react-feather'
import { Link, RouteComponentProps } from 'react-router-dom'
// import { useWalletModalToggle } from 'state/application/hooks'
import { useKYContractState } from 'state/kycontract/hooks'
import { useUpdateTokenData } from 'state/tokens/hooks'
import { getTokenPriceParamByTokenAndPrice } from 'state/tokens/reducer'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import BatchFees from '../../components/BatchFees'
import { useAccountManager } from '../../constants/accounts'
import { PoolOverviewManager } from '../../constants/poolOverview'
import { usePositionsData } from '../../hooks/usePositionsData'
import PoolOverview from './PoolOverview'
import { LoadingRows } from './styleds'

export const PageWrapper = styled(AutoColumn)`
  max-width: 100%;
  width: 100%;
  min-width: 800px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
export const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
  //max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-right: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `};
`
const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`
const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 49%;
  `};
`

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

const ShowInactiveToggle = styled.div`
  display: grid;
  align-items: center;
  justify-items: end;

  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
  padding: 0 8px;
`

const PoolOverviewBox = styled(Paper)`
  //max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  box-shadow: 0 0 16px 0 rgba(79, 120, 224, 0.08);
  margin: 8px 0 0;
  box-sizing: border-box;
  padding: 20px;
`

const PositionCheckDetail = styled.div`
  display: inline-block;
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function Position({
  match: {
    params: { address: addressFromUrl },
  },
}: RouteComponentProps<{ address?: string }>) {
  // 改了一种写法，现在从redux里获取这个contractAddress参数了，而不是像之前每一个page都要单独获取
  // const contractAddress = parseAddress(addressFromUrl)
  const { contractAddress, isOfficialContract } = useKYContractState()

  const { selectedAccount } = useAccountManager()
  // const toggleWalletModal = useWalletModalToggle()
  const account = selectedAccount !== '' ? selectedAccount : undefined
  // const { account: myAccount, chainId } = useActiveWeb3React()
  const { account: myAccount, chainId } = useWeb3React()

  const theme = useContext(ThemeContext)
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions, loading: positionsLoading } = useV3Positions(contractAddress)

  const { handleOperationButtonClick } = useOperationButtonClick()

  const [openPositions, closedPositions] = positions?.reverse().reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
  const allPositions = [...openPositions, ...closedPositions]

  const [positionsData, setPositionsData, emptyPositions] = usePositionsData()

  const [selected, setSelected] = useState<string[]>([])
  useEffect(() => {
    emptyPositions()
    setSelected([])
  }, [selectedAccount])

  const noDupulicateTokenAddress = useMemo(() => {
    const noDupulicateTokenAddress = openPositions.reduce<string[]>((t, v) => {
      const token0Address = v.token0.toLowerCase()
      const token1Address = v.token1.toLowerCase()
      t = t.includes(token0Address) ? t : [...t, token0Address]
      t = t.includes(token1Address) ? t : [...t, token1Address]
      return t
    }, [])
    return noDupulicateTokenAddress
  }, [filteredPositions.length])

  function removeAddress(tokenAddress: string) {
    const index = noDupulicateTokenAddress.indexOf(tokenAddress)
    if (index >= 0) {
      noDupulicateTokenAddress.splice(index, 1)
    }
  }

  const default_weth_address = chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : 'ETH'

  return (
    <>
      <PageWrapper>
        <CheckIsOfficialContract />
        {noDupulicateTokenAddress.map((item) => {
          return <RequestTokenPriceComponent key={item} fn={removeAddress} tokenAddress={item} />
        })}
        <SwapPoolTabs active={'pool'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <PoolOverviewManager.Provider value={{ positionsData, setPositionsData, emptyPositions }}>
              <PoolOverviewBox elevation={0}>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <PoolOverview openPositions={openPositions} closedPositions={closedPositions} />
                  <PoolAccountInfo />
                </Stack>
              </PoolOverviewBox>
              <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                <PositionCheckDetail>
                  <BatchFees selected={selected} />
                </PositionCheckDetail>
                <ButtonRow>
                  {closedPositions.length > 0 ? (
                    <ShowInactiveToggle>
                      <ThemedText.DarkGray>
                        <Trans>Hide closed positions</Trans>
                      </ThemedText.DarkGray>
                      <Toggle
                        isActive={userHideClosedPositions}
                        toggle={() => setUserHideClosedPositions(!userHideClosedPositions)}
                      />
                    </ShowInactiveToggle>
                  ) : null}
                  {myAccount && isOfficialContract ? (
                    <>
                      <ResponsiveButtonPrimary
                        id="join-pool-button"
                        as={Link}
                        to={`/add/${contractAddress}/${default_weth_address}`}
                        // onClick={() => {
                        //   handleOperationButtonClick(`/add/${contractAddress}/${default_weth_address}`)
                        // }}
                        // disabled={!isAdmin && !isTrader}
                      >
                        + <Trans>New Position</Trans>
                      </ResponsiveButtonPrimary>
                      <ResponsiveButtonPrimary
                        id="swap-pool-button"
                        as={Link}
                        to={`/swap/${contractAddress}?outputCurrency=${default_weth_address}`}
                        // onClick={() => {
                        //   handleOperationButtonClick(`/swap/${contractAddress}?outputCurrency=${default_weth_address}`)
                        // }}
                        // disabled={!isAdmin && !isTrader}
                      >
                        Swap
                      </ResponsiveButtonPrimary>
                    </>
                  ) : null}
                </ButtonRow>
              </TitleRow>

              {/* <MainContentWrapper>
                {positionsLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                  <PositionList
                    positions={filteredPositions}
                    setUserHideClosedPositions={setUserHideClosedPositions}
                    userHideClosedPositions={userHideClosedPositions}
                  />
                ) : (
                  <NoLiquidity>
                    <ThemedText.Body color={theme.text3} textAlign="center">
                      <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                      <div>
                        <Trans>Your active V3 liquidity positions will appear here.</Trans>
                      </div>
                    </ThemedText.Body>
                    {closedPositions.length > 0 && (
                      <ButtonText
                        style={{ marginTop: '.5rem' }}
                        onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                      >
                        <Trans>Show closed positions</Trans>
                      </ButtonText>
                    )}
                    {showConnectAWallet && (
                      <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                        <Trans>Connect a wallet</Trans>
                      </ButtonPrimary>
                    )}
                  </NoLiquidity>
                )}
              </MainContentWrapper> */}
              {/* 之前的 */}

              <MainContentWrapper>
                {positionsLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                  <>
                    {/* <PositionList positions={filteredPositions} selected={selected} setSelected={setSelected} /> */}
                    <PositionList
                      positions={filteredPositions}
                      setUserHideClosedPositions={setUserHideClosedPositions}
                      userHideClosedPositions={userHideClosedPositions}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                ) : (
                  <NoLiquidity>
                    <ThemedText.MediumHeader color={theme.text3} textAlign="center">
                      <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                      <div>
                        <Trans>Your V3 liquidity positions will appear here.</Trans>
                      </div>
                    </ThemedText.MediumHeader>
                    {!contractAddress ? (
                      <ButtonPrimary
                        style={{ marginTop: '2em', padding: '8px 16px' }}
                        onClick={() => {
                          console.log('im click')
                        }}
                      >
                        不是正确的地址，请在 交易合约管理 中添加地址或URL中输入地址
                      </ButtonPrimary>
                    ) : (
                      <div></div>
                    )}
                  </NoLiquidity>
                )}
              </MainContentWrapper>
            </PoolOverviewManager.Provider>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}

interface IRequestTokenPriceComponent {
  fn: (tokenAddress: string) => void
  tokenAddress: string
}

function RequestTokenPriceComponent(props: IRequestTokenPriceComponent) {
  const { fn, tokenAddress } = props
  const token = useToken(tokenAddress)
  const usdcPrice = useUSDCPrice(token ?? undefined)
  // const { chainId } = useActiveWeb3React()
  const { chainId } = useWeb3React()
  const { onUpdateTokenPrice } = useUpdateTokenData()
  if (usdcPrice && token) {
    const { symbol, address, denominator, numerator, price } = getTokenPriceParamByTokenAndPrice(token, usdcPrice)
    onUpdateTokenPrice(chainId?.toString(), symbol, address, denominator, numerator, price)
    fn(tokenAddress)
  }
  return null
}
