import { useWeb3React } from '@web3-react/core'
// eslint-disable-next-line no-restricted-imports
import { getDefaultProvider } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { isAddress } from 'utils'
import { BlockchainProvider } from 'utils/blockchainprovider'
import { parseAddress } from 'utils/parseAddress'

// import { useActiveWeb3React } from './web3'

export function useContractAddress(address: string | undefined) {
  const [contractAddress, setContractAddress] = useState<string | undefined>()
  // const { chainId } = useActiveWeb3React()
  const { chainId } = useWeb3React()

  useEffect(() => {
    if (!address || typeof chainId === 'undefined') return
    const checkAddress = parseAddress(address)
    if (!checkAddress) return
    const provider = BlockchainProvider.getDefaultProvider(chainId) ?? getDefaultProvider()
    // const provider = getDefaultProvider()
    provider.getCode(checkAddress).then((res) => {
      // 说明是普通地址
      if (res === '0x') setContractAddress(undefined)
      else setContractAddress(checkAddress)
    })
  }, [address, chainId])
  return contractAddress
}

export function useURLContractAddress() {
  const { contractAddress: checkAddress } = useParams<{ contractAddress?: string }>()
  const contractAddress = useContractAddress(checkAddress)
  return contractAddress
}

// 通过URL获取contract address，但是这个的前提是contractAddress必须在页面之后的第一个参数：比如 /path/contractAddress/otherAddress
export function useContractAddressByLocation() {
  const location = useLocation()
  const pathname = location.pathname
  return useMemo(() => {
    const params = pathname.split('/')
    let contractAddress: string | undefined = undefined
    for (const param of params) {
      const needCheckAddress = isAddress(param)
      if (needCheckAddress) {
        contractAddress = needCheckAddress
        break
      }
    }
    return contractAddress
  }, [pathname])
}
